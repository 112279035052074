<template>
  <!-- 产品-小象脑力 -->
  <div class="website-css Product-WLZG">
    <!-- banner beg -->
    <div class="Solution-banner AboutUs-banner">
      <div class="w1200">
        <img
          src="../assets/image/Website-img/xxnl-logo.png"
          class="wlzg-banner-icon wlzg-banner-logo"
        />

        <img
          src="../assets/image/Website-img/xxnl-banner1.png"
          class="wlzg-banner-icon wlzg-banner-icon3 opacity1 animated pulse"
        />
        <img
          src="../assets/image/Website-img/xxnl-banner2.png"
          class="wlzg-banner-icon wlzg-banner-icon2 animated fadeInRight"
        />

        <div class="banner-title">
          <p class="animated pulse">{{ this.languageList.bannertitle }}</p>
          <p>{{ this.languageList.bannerContent }}</p>
          <img
            class="wlzg-banner-ewm animated tada"
            src="../assets/image/Website-img/xxnl-ewm.png"
          />
          <p class="ewm-text">{{ this.languageList.bannerContent1 }}</p>
        </div>
        <!-- <img src="../assets/image/Website-img/bannericon1.png" class="banner-icon3" /> -->
      </div>
    </div>
    <!-- banner end -->

    <!--模块一 多方面提升 beg-->
    <div class="w1200">
      <div class="wlzg-box">
        <div class="wlzg-box-text">
          <p><span></span>{{ this.languageList.list[0].title }}</p>
          <p>
            {{ this.languageList.list[0].content }}
          </p>
        </div>
        <div class="wlzg-box-img">
          <img :src="this.languageList.list[0].img" class="product1" />
        </div>
      </div>
    </div>
    <!--模块一 多方面提升 end-->

    <!--模块二 科学性转化 beg-->
    <div class="Enterprise-bg-f8">
      <div class="w1200">
        <div class="wlzg-box">
          <div class="wlzg-box-img">
            <img
              src="../assets/image/Website-img/xxnl-product2.png"
              class="product2"
            />
          </div>
          <div class="wlzg-box-text text-right">
            <p><span></span>{{ this.languageList.list[1].title }}</p>
            <p>
              {{ this.languageList.list[1].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--模块二 科学性转化 beg-->

    <!--模块三 个性化推荐 beg-->
    <div class="w1200">
      <div class="wlzg-box">
        <div class="wlzg-box-text">
          <p><span></span>{{ this.languageList.list[2].title }}</p>
          <p>
            {{ this.languageList.list[2].content }}
          </p>
        </div>
        <div class="wlzg-box-img">
          <img
            src="../assets/image/Website-img/xxnl-product3.png"
            class="product1"
          />
        </div>
      </div>
    </div>
    <!--模块三 个性化推荐 end-->

    <!--模块四 游戏化机制 beg-->
    <div class="Enterprise-bg-f8">
      <div class="w1200">
        <div class="wlzg-box">
          <div class="wlzg-box-img">
            <img
              src="../assets/image/Website-img/xxnl-product4.png"
              class="product2"
            />
          </div>
          <div class="wlzg-box-text text-right">
            <p><span></span>{{ this.languageList.list[3].title }}</p>
            <p>
              {{ this.languageList.list[3].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--模块四 游戏化机制 beg-->

    <web-bottom ref="child"></web-bottom>
  </div>
</template>

<script>
import WebBottom from '../components/WebBottom'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
export default {
  name: 'AboutUs',
  components: {
    WebBottom,
  },
  metaInfo: {
    title: '小象脑力-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keywords',
        content: '脑科学,脑能力维度,培养脑科学,培养脑科学机构',
      },
      {
        name: 'description',
        content:
          '多方面提升,科学性转化,个性化推荐,游戏化机制,结合3-10岁儿童发展规律以及儿童日常学习和生活所需,构建记忆、思维、注意、语言、观察、反应6类脑能力维度,培养脑科学机构为儿童量身定制个性化训练方案,并根据后续训练情况进行相应调整,激发儿童学习的积极性,促进儿童的观察、记忆、反应等认知能力的开发和培养,让儿童身心得到协调发展.',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
    }
  },
  props: {
    msg: String,
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // console.log("语言类型", this.langtype);
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      console.log('语言类型', this.langtype)
      this.IFType()
    }
  },
  mounted() {},
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        console.log('中文配表', this.languageJson)
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      console.log('home', data)
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      console.log('中文配表', this.languageJson)
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'Product-XXNL') {
          this.languageList = item.List[0]
        }
      })
      console.log(this.languageList)
    },
  },
}
</script>
<style scoped>
.AboutUs-banner {
  width: 100%;
  height: 715px;
  position: relative;
  background: linear-gradient(to right, #27ccfe, #0d66fa);
}
.Solution-banner {
}
.brain-ul li {
  float: left;
  width: 400px;
  height: 300px;
}
</style>